import { Button } from '@mui/material';
import { Dropdown } from '@mui/base/Dropdown';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem  } from '@mui/base/MenuItem';
import { Menu } from '@mui/base/Menu';
import { styled } from '@mui/system';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function DropdownMenu({pages, label}) {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Dropdown onOpenChange={()=> setIsOpen(!isOpen)}>
                <MenuButton style={{background: "#c5e1a5", border: "none"}}>
                    <Button sx={{ color: "black", textTransform: "none", fontSize: "calc(0.5vw + 10px)", height: "100%" }} className='navButtons' style={{background: "#c5e1a5"}}>
                            {label} {isOpen ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    </Button> 
                </MenuButton>
                <Menu slots={{listbox: Listbox}}>
                    {pages.map((page, index) => 
                        <MenuItem key = {index}>
                            <Button sx={{ color: "black", textTransform: "none", fontSize: "calc(0.5vw + 10px)" }} className='navButtons' style={{background: "#c5e1a5"}} component={Link} 
                                onClick={() => {
                                            document.body.style.overflow = 'auto'
                                            window.scrollTo(0, 0);
                                        }}
                                        to={"/" + page}>
                                    {page.replaceAll("-", " ")}
                            </Button>
                        </MenuItem>
                    )}
                </Menu>
            </Dropdown>
        </>
    )
}

  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 0;
    min-width: 200px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: #c5e1a5;
    border: 1px solid #f2f7f4;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 4px 6px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
    };
    z-index: 1;
    `,
  );


  
  