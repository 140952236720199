import './SecondYearHighlights.css';
import bookPaper from './media/bookPaper.pdf';
import musicPaper from './media/ways_of_listening.pdf';
import compo3Audio from './media/compo3.mp3';
import compo4Audio from './media/Compo4mp3.mp3';
import Carousel from 'react-material-ui-carousel';
import { useState, useEffect } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import pic1 from './media/pic1.png';
import pic2 from './media/pic2.png';

export default function SecondYearHighlights() {

    const [autoplay, setAutoplay] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const pics = [pic1, pic2];

    window.addEventListener('keydown', function (e) {
        if (isFullscreen && e.key === "Escape") {
            setIsFullscreen(!isFullscreen);
        }
    });

    function toggleFullscreen() {
        setIsFullscreen(!isFullscreen);
    }

    useEffect(() => {
        if (isFullscreen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
    }, [isFullscreen]);

    return (
        <>
            <div className='mainBodyFirstYearHighlights'>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Second Year Highlights</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                        On this page I've collated some of the highlights from my second two semesters at UVA. This year, I continued to learn new skills as well as perfect old ones. 
                    </h1>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Studies in Fiction; Contemporary Fiction</h1>


                    <div className='firstYearHighlightsContent'>

                        <div className='contentHalf'>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                This year i took ENGL 2508 as a second writing requirement. It was an opportunity for me to further my writing abilities and engage with books I would have never found otherwise. 
                                I've attached my third paper from the class to this page. The assignment for this paper was very open ended, so the challenge was to choose a book and a topic to write about. Through 
                                this paper I learned how to better articulate my thoughts about fiction, and how to parse books for themes and analysis. 
                            </h1>

                            <div style={{
                                display: "flex", justifyContent: "space-between", width: "calc(20% + 150px)", marginLeft: "auto", marginRight: "auto",
                                paddingBottom: "10px", paddingTop: "30px"
                            }}>
                            </div>
                        </div>

                        <object
                            type="application/pdf"
                            data={bookPaper + "#view=FitH"}
                            className="highlightPDF"
                            aria-label="Book Essay."
                        />

                    </div>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Music</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", textAlign: "left", }}>
                        In my second year at UVA, I took three music classes. The highlights from those classes are 
                        MUSI 3050 (Music and Discourse Since 1900) and MUSI 3390 (Intro to Music and Computers). In those two classes I learned to 
                        better analyze music and discuss it critically, as well as how to make my own compositions. There are a few projects from these
                        two classes below.
                    </h1>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Ways of Listening Paper</h1>


                    <div className='firstYearHighlightsContent'>

                        <div className='contentHalf'>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                To the left is my ways of listening essay from MUSI 3050. The goal of this essay was to emulate the structure of Ben Ratliff's 
                                <i>Every Song Ever: Twenty Ways to Listen in an Age of Musical Plenty</i>. This book explores different uniting methods or themes across the musical 
                                world from the tempo of a song to its atmosphere. I was tasked with creating my own way of listening for music, one that had to incorporate a variety
                                of musical genres from many time periods. 
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockTopStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                My essay is about "bad singing". I wanted to explore the ways artists use non-perfect or non-standard singing styles to improve a song. I had to 
                                choose songs from a variety of artists in order to draw a cross-genre connection. Researching and writing this essay expanded my musical boundaries
                                and forced me to listen to songs and genres I wouldn't listen to otherwise. And once I identified a song to write about, I had to determine how
                                I felt the singing style benefits the piece overall. This essay helped me to critically examine elements of music and use them to draw larger conclusions
                                about things like themes and mood. I am a lot better at parsing music now thanks to this project and this class. 
                            </h1>
                        </div>

                        <div className='pdfAudio'>
                        <object
                                type="application/pdf"
                                style={{ width: "100%" }}
                                data={musicPaper + "#view=FitH"}
                                className="highlightPDF"
                                aria-label="Music Paper"
                            />
                        </div>
                    </div>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Computer Music Compositions</h1>


                    <div className='firstYearHighlightsContent'>

                        <div >

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                In this section I've included two of my compositions from MUSI 3390. In this class we learned how to work with music-making software and
                                how to create and use synths in original compositions. This was my first experience making my own music, so there was lots of trial and error.
                                In this section are my third and fourth compositions, along with a writeup for each one. 
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                Below is my third composition from MUSI 3390. The goal with this composition was to use MIDI, a digital interface used to connect instruments to computers,
                                to program a detailed song with synth sounds created in VCV Rack, a synth creation app. This piece follows the 12 bar blues, and my goal with it was to 
                                combine the electronic sounds I created with the classic sound of the 12 bar blues. The result is something that feels old and new at the same time. 

                            </h1>

                            <audio controls style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <source src={compo3Audio} type='audio/ogg' />
                            </audio>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                Next is my fourth composition from MUSI 3390, which was the final project for the class. The goal with this project was to revamp
                                one of my previous compositions. As such, this piece contains elements from my second composition which was pretty simple and unremarkable.
                                For this song I was inspired by 8-bit music from classic video games like Earthbound and Mega Man 2. This song features complex chords with
                                many different sections and tempo changes. After the intro section, the song generally follows an ABA pattern. I'm very proud of how this piece turned out,
                                and I think it really captures the video game sound I was going for. 
                            </h1>

                            <audio controls style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <source src={compo4Audio} type='audio/ogg' />
                            </audio>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}