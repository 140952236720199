import Carousel from 'react-material-ui-carousel';
import './ForgeLaunch.css';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GitHubIcon from '@mui/icons-material/GitHub';
import { IconButton, ButtonGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import easyBuyLogo from './images/logo.png';
import spottsLogo from './images/logo-low-res.png';
import forgeLogo from './images/forge-linear-primary.png';
import easybuy1 from './images/easybuy1.png';
import easybuy2 from './images/easybuy2.png';
import easybuy3 from './images/easybuy3.png';
import easybuy4 from './images/easybuy4.png';
import spots1 from './images/spots1.png';
import spots2 from './images/spots2.png';
import spots3 from './images/spots3.png';
import spots4 from './images/spots4.png';
import forgeInfo from './document.pdf';


export default function ForgeLaunch() {

    let easyBuyPics = [
        easybuy1, easybuy2, easybuy3, easybuy4
    ]

    let spotsPics = [
        spots1, spots2, spots3, spots4
    ]

    const [autoplay, setAutoplay] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [whichPics, setWhichPics] = useState();

    window.addEventListener('keydown', function (e) {
        if (isFullscreen && e.key === "Escape") {
            setIsFullscreen(!isFullscreen);
        }
    });

    function toggleFullscreen(picArray) {
        setWhichPics(picArray);
        setIsFullscreen(!isFullscreen);
    }

    useEffect(() => {
        if (isFullscreen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
    }, [isFullscreen]);

    return (
        <>
            <div className='mainBodyForgeLaunch'>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Forge Launch</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                        During my first year at UVA, I participated in the Forge Launch internship matching program. Forge is a Charlottesville-based
                        company that provides technical training and internship prep to the greater UVA community. As a member of the Forge Launch program, I
                        was trained in both technical and professional skills.
                    </h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", paddingTop: "20px", paddingBottom: "20px", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                        I was a member of the software engineering track, where I learned various technologies including JavaScript, React, Git/Github, html, and more.
                        On this page I've included some of the React projects I created during the Spring semester.
                    </h1>

                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>EasyBuy E-commerce Site</h1>

                    <div className='forgeLaunchContent'>

                        <div className="contentHalfImage">
                            <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                                {easyBuyPics.map((pic, index) =>
                                    <img src={pic} key={index} className='pics' alt='Screenshot of easybuy website' />
                                )}
                            </Carousel>
                            <div className='carouselButtons'>
                                <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                    {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>

                                <IconButton onClick={() => toggleFullscreen(easyBuyPics)}>
                                    <FullscreenIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className="contentHalf" >
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                                My final Forge Launch project was to make an E-commerce site. It was a group project, and my team settled on the name EasyBuy.
                                The project was made with React for the frontend, and Express.js, Google Firebase, and Stripe for the backend. I worked mostly on the backend
                                as well as the product and search results pages. This project was the final test of my skills I had learned through Launch, requiring extensive
                                planning including wireframes, Trello boards, and prioritization of features. I was able to gain a deeper understanding of React through this project,
                                and I perfected my ability to learn on my own.
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingTop: "20px", textAlign: "left", marginBlockEnd: "0" }}>
                                Easy buy is currently deployed! You can visit the site as well as the GitHub repository below.
                                (Note: the backend API takes a bit to warm up when it hasn't been used in a while, give it 30 seconds or so and everything will load properly).
                            </h1>
                        </div>

                    </div>

                    <ButtonGroup sx={{ width: "10%", margin: "auto", justifyContent: "center", paddingTop: "20px" }}>

                        <a href='https://shop-easybuy.netlify.app/' target='_blank' rel='noreferrer'>
                            <IconButton sx={{ color: "black" }}>
                                <img src={easyBuyLogo} style={{ width: "70px" }} alt='easyBuy logo' />
                            </IconButton>
                        </a>

                        <FiberManualRecordIcon sx={{ margin: "auto", width: "50%", fontSize: "10px" }} />

                        <a href='https://github.com/EthanHaller/ForgeWeek3Team1' target='_blank' rel='noreferrer'>

                            <IconButton>
                                <GitHubIcon sx={{ fontSize: "70px", color: "black" }} />
                            </IconButton>
                        </a>

                    </ButtonGroup>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Spots Spotify Extension App</h1>

                    <div className='forgeLaunchContent'>

                        <div className="contentHalfImage">
                            <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                                {spotsPics.map((pic, index) =>
                                    <img src={pic} key={index} className='pics' alt='Screenshot of Spots website' />
                                )}
                            </Carousel>
                            <div className='carouselButtons'>
                                <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                    {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>

                                <IconButton onClick={() => toggleFullscreen(spotsPics)}>
                                    <FullscreenIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className="contentHalf" >
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                                My week 2 project for Forge Launch was to make a Spotify extension that would display statistics about your Spotify listening habits.
                                The app also needed to have user profiles, a way to search for other profiles, and forums/direct messaging. I worked mostly on creating the user
                                profiles, the navigation, the login, the homepage, the backend, and the discover tab for finding other profiles. Additionally, I've gone back and
                                touched up some features that my teammates worked on since concluding the project. This project was all about working with APIs and was the first time we
                                separated our frontend from our backend. Learning Express.js to handle the backend API was tough, but was very rewarding once we figured it out.
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingTop: "20px", textAlign: "left", marginBlockEnd: "0" }}>
                                Spots is currently deployed online as well.You can find it and the GitHub repository below. Like above, give the site 30 seconds to warm up.
                                You'll then be prompted to login with your Spotify account, and after that enter "TestUser1" for username and "password" for password to get into the site.
                            </h1>
                        </div>

                    </div>

                    <ButtonGroup sx={{ width: "10%", margin: "auto", justifyContent: "center", paddingTop: "20px" }}>

                        <a href='https://spotts.netlify.app/' target='_blank' rel='noreferrer'>
                            <IconButton sx={{ color: "black" }}>
                                <img src={spottsLogo} style={{ width: "70px" }} alt='spots logo' />
                            </IconButton>
                        </a>

                        <FiberManualRecordIcon sx={{ margin: "auto", width: "50%", fontSize: "10px" }} />

                        <a href='https://github.com/ty-donovan/spotify-extension-app' target='_blank' rel='noreferrer'>

                            <IconButton>
                                <GitHubIcon sx={{ fontSize: "70px", color: "black" }} />
                            </IconButton>
                        </a>

                    </ButtonGroup>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>More About Forge Launch</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                        The Forge Launch program consists of light technical and professional training during the Fall and Spring semester,
                        followed by 3 weeks of full-time technical training and then a Summer internship. Launch members begin interviewing with companies in February,
                        and internships start in June.
                    </h1>

                    <div className='forgeLaunchContent'>
                        <div style={{ backgroundColor: "#252925", width: "49.5%" }}>
                            <img src={forgeLogo} style={{ width: "100%" }} alt='forge logo' />
                        </div>

                        <div className='contentHalf'>
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left" }}>
                                Below is the software engineering curriculum for Forge Launch.
                            </h1>
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left" }}>
                                <a href='https://joinforge.co/launch' target='_blank' rel='noreferrer'>Forge Launch homepage</a>
                            </h1>
                        </div>
                    </div>

                    <object
                        type="application/pdf"
                        data={forgeInfo + "#view=FitH"}
                        className="resume"
                        style={{ marginTop: "20px" }}
                        aria-label='PDF of Forge Launch curriculum.'
                    />
                </div>


            </div>

            {isFullscreen &&
                <>
                    <div className="fullscreenImage" style={{ top: window.scrollY + (window.screen.height / 10) }}>
                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                            {whichPics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of website' style={{ maxHeight: "39vw" }} />
                            )}
                        </Carousel>
                        <div className='carouselButtons'>
                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={() => toggleFullscreen("")}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className='forgeBlocker' />
                </>}
        </>
    )
}