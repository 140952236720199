import './ReflectionOnLearning.css';

export default function ReflectionOnLearning() {
    return (
        <>
            <div className='mainBodyReflectionOnLearning'>

                <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Reflection on Learning</h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    Learning at college is very different from high school. In a few short months, my learning environment
                    changed from hours and hours of time spent in class with 8+ classes to short lectures and far fewer courses.
                    I had to change how I go about my learning, and understanding this new reality shaped my first year at UVA.

                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    American Economic History, an elective I took for fun and to satisfy gen-ed requirements,
                    proved to be what taught me to adjust. The class was unforgiving–hundreds of pages of readings and
                    textbook chapters were assigned every week. I learned that to succeed in the class I would have to
                    dedicate lots of time outside of lecture, something I never had to do in high school. College came
                    with lots of independence and free time to explore my passions, but it also came with less oversight
                    and professors that won’t help unless you ask first. I ended up being one of the 4% of students that got
                    an A in American Economic History, a class with over 250 students. Even though I didn’t need the class for
                    either of my majors, it felt amazing to see how the countless hours I spent preparing and studying wheat
                    prices in colonial America paid off. I learned to appreciate the difficulty and rigor that college provides
                    as it allows me to feel like my time is well spent and that I benefit from the effort I put into school.
                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    Going into my second semester, I was able to start taking more classes for my majors, and to take ones that
                    interested me. Perhaps the most fun, and one of the more difficult, classes I took in my second semester was
                    Musicianship. In high school, I always had a desire to learn more about the theoretical and technical side of music,
                    but I never had the opportunity to pursue that until college. Learning how to identify intervals, chords,
                    chord progressions, and harmonies was a shockingly huge challenge for me that I did not expect from the class.
                    I had to completely unlearn my wiring as an instrumentalist who had always had sheet music and concrete ways to
                    identify notes on my instrument, and I had to learn how to use my voice and my ears to analyze music. Most of
                    my classmates were singers, so the material came frustratingly easy to them. However, through my perseverance
                    I was able to create systems and rules to follow that allowed me to succeed. When identifying intervals I
                    would use popular songs as a reference, allowing me to use what I already knew, when dictating harmonic
                    progressions I would use my knowledge of music theory that I was learning at the same time in a different
                    class to recognize patterns of dominant, predominant, and tonic chords, and when dictating the rhythms of
                    melodies I came up with symbols to represent each type of note so I could work faster without having to focus
                    one bar at a time. These are skills that I couldn’t have imagined I would have going into UVA, but now,
                    I use them all the time.
                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    Although it seems very different at first, the mindset and skills I learned from my music education
                    greatly helped me with my computer science learning. In Data Structures and Algorithms, a foundational
                    programing course, I learned to use my new way of thinking to improve my coding. Much of the process of learning
                    about foundational data structures like hashtables, linkedlists, and binary search trees is recognizing patterns
                    in their applications. When coding a hashtable, for example, turning my theoretical and diagram based knowledge
                    of the data structure into recursive code required me to recognize what actions are repeated every time a
                    key-value pair is added to the hashtable, and which actions are dependent on other actions to activate or
                    iterate. By coding these structures from scratch, I gained an intimate knowledge of their strengths and
                    weaknesses, and when to use them in real applications. I know now that a linkedlist uses less space in
                    memory but has longer retrieve and find functions, and that a hashtable has a long add and remove time,
                    but has a nearly instantaneous search function.

                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    In high school, almost every class simply required memorization of detail and regurgitation of information.
                    While I have encountered similar classes at UVA, the vast majority of them require transformative thinking
                    and the creation of content from my own mind. High school me never would’ve figured out how to connect Star Wars
                    songs to music ear training, and he wouldn’t have been able to understand the subtle differences between a binary
                    search tree and an AVL tree. The biggest thing I learned from UVA in my first year was my mindset. The classes made
                    me a more independent learner, and taught me new ways to approach and compartmentalize problems to divide-and-conquer
                    my learning. As I continue my college career, I can’t wait to see what I have left to learn.

                </h1>

            </div>
        </>
    )
}