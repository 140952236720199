import './Homepage.css';
import picOfMe from './images/pic_of_me.png';
import { IconButton, ButtonGroup, Divider } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function Homepage() {

    return (
        <>
            <div className="mainBody">
                <div className='textBox'>
                    <h1 className="mainText" style={{ marginBottom: "calc(3vw + 20px)" }}>
                        My name is Nolan Hill, and I am currently a third year at the University of Virginia. I was born and raised in Leesburg, Virginia, although
                        I did spend some time living in Stuttgart, Germany! I plan on double majoring in computer science and music, and I'm excited to continue growing my
                        coding and web development skills.  Upon graduation I will be seeking a fulltime software engineering role, and am open to relocation.
                    </h1>
                    <h1 className="mainText">
                        "Not all those who wander are lost"
                    </h1>
                    <h1 className="mainText">
                        -J.R.R. Tolkien
                    </h1>
                    <h1 className="mainText">
                        A reminder that the strengths of others are not always visible at first, and to carry compassion everywhere you go.
                    </h1>
                    <Divider sx={{ marginTop: "60px", backgroundColor: "black", marginLeft: "5%", marginRight: "5%", borderBottomWidth: "3px" }} />
                    <h1 className='mainText'>
                        Contact:
                    </h1>
                    <h1 className='mainText'>
                        <a href='mailto: nolanreedhill@gmail.com'>
                            nolanreedhill@gmail.com
                        </a>
                    </h1>
                    <ButtonGroup sx={{ width: "10%", margin: "auto", justifyContent: "center" }}>
                        <a href='https://www.linkedin.com/in/nolanreedhill/' target='_blank' rel='noreferrer'>
                            <IconButton>
                                <LinkedInIcon sx={{ fontSize: "70px", color: "black" }} />
                            </IconButton>
                        </a>
                        <FiberManualRecordIcon sx={{ margin: "auto", width: "50%", fontSize: "10px" }} />
                        <a href='https://github.com/NolanReedHill' target='_blank' rel='noreferrer'>
                            <IconButton>
                                <GitHubIcon sx={{ fontSize: "70px", color: "black" }} />
                            </IconButton>
                        </a>
                    </ButtonGroup>
                </div>
                <img src={picOfMe} className='picOfMe' alt='Nolan Hill' />

            </div>
        </>
    );
}