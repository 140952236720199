import {
    AppBar, Toolbar, Button, Box, IconButton, ButtonGroup, Drawer, List, ListItem, ListItemButton, ListItemText, Divider
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useWindowSize from './useWindowSize';
import DropdownMenu from './DropdownMenu';

export default function Navbar() {

    const size = useWindowSize();
    const [openDrawer, setOpenDrawer] = useState(false);

    const allPages = ["Resume", "Forge-Launch",  "Values", "Social", "Reflection-on-Learning"];
    const allProjects = [  "BiteBack", "Hoos-Hack-24", "Games-App",];
    const allHighlights = [ "Second-Year-Highlights", "First-Year-Highlights", ];
    return (
        <>
            <AppBar>
                <Toolbar>
                    {size.width >= 900 &&
                        <>
                            <IconButton component={Link} to={"/"} sx={{ marginRight: "10px" }}>
                                <HomeIcon sx={{ color: "black" }} fontSize='large' />
                                <h3 style={{ marginBlockStart: "0.5em", marginBlockEnd: "0.5em", color: "black", marginLeft: "10px" }}>Nolan Hill</h3>
                            </IconButton>
                            <ButtonGroup disableElevation >

                                <DropdownMenu pages={allProjects} label="Projects"/>

                                <DropdownMenu pages={allHighlights} label="Yearly Highlights"/>

                                {allPages.map((page, index) =>
                                    <Button sx={{ color: "black", textTransform: "none", fontSize: "calc(0.5vw + 10px)" }} className='navButtons' component={Link}
                                        onClick={() => {
                                            document.body.style.overflow = 'auto'
                                            window.scrollTo(0, 0);
                                        }}
                                        to={"/" + page} key={index}>{page.replaceAll("-", " ")}</Button>
                                )}
                            </ButtonGroup>
                        </>}
                    {size.width < 900 &&
                        <>
                            <IconButton sx={{ color: "black", position: "fixed" }} onClick={() => setOpenDrawer(true)}>
                                <MenuIcon fontSize='large' />
                            </IconButton>
                            <Drawer
                                anchor='left'
                                open={openDrawer}
                                onClose={() => setOpenDrawer(false)}
                                PaperProps={{
                                    sx: {
                                        backgroundColor: "#bacbbb",
                                    }
                                }}
                            >
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <IconButton sx={{ color: "black", marginLeft: "5%" }} component={Link} to={"/"} onClick={() => setOpenDrawer(false)}>
                                        <HomeIcon fontSize='large' />
                                        <h3 style={{ marginLeft: "10px" }}>Nolan Hill</h3>
                                    </IconButton>
                                    <IconButton onClick={() => setOpenDrawer(false)}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                </Box>
                                <Divider color="black" />
                                <List
                                    disablePadding
                                    sx={{ width: { xs: "70vw", sm: "300px" }, mb: "75px" }}>
                                    {allPages.map((page, index) =>
                                        <>
                                            <ListItem key={index} sx={{ py: "0" }}>
                                                <ListItemButton
                                                    component={Link}
                                                    to={"/" + page}
                                                    onClick={() => setOpenDrawer(false)}>
                                                    <ListItemText
                                                        primary={page.replaceAll("-", " ")}
                                                        primaryTypographyProps={{
                                                            fontSize: "calc(16px + 0.3vw)",
                                                        }} />
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    )}
                                </List>
                            </Drawer>
                            <IconButton component={Link} to={"/"} sx={{ marginLeft: "auto", marginRight: "auto" }}>
                                <HomeIcon sx={{ color: "black" }} fontSize='large' />
                                <h3 style={{ marginBlockStart: "0.5em", marginBlockEnd: "0.5em", color: "black", marginLeft: "10px" }}>Nolan Hill</h3>
                            </IconButton>
                        </>}

                </Toolbar>
            </AppBar>
        </>
    );
}