import Carousel from 'react-material-ui-carousel';
import './HoosHack24.css';
import { useState, useEffect } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import GitHubIcon from '@mui/icons-material/GitHub';
import pic1 from './media/image.jpg';
import pic2 from './media/image2.jpg';
import pic3 from './media/i2mage.jpg';
import pic4 from './media/ima3ge.jpg';

export default function HoosHack24() {
    const [autoplay, setAutoplay] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    window.addEventListener('keydown', function (e) {
        if (isFullscreen && e.key === "Escape") {
            setIsFullscreen(!isFullscreen);
        }
    });

    let pics = [
        pic1, pic2, pic3, pic4
    ]

    function toggleFullscreen() {
        setIsFullscreen(!isFullscreen);
    }

    useEffect(() => {
        if (isFullscreen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
    }, [isFullscreen]);

    return (
        <>
            <div className='mainBodyGamesApp'>

                <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Hoos Hack '24</h1>

                <div className='gamesAppContent'>

                    <div className="contentHalfImage">

                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                            {pics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of games app website' style={{width: "60%"}}/>
                            )}
                        </Carousel>

                        <div className='carouselButtons'>

                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={toggleFullscreen}>
                                <FullscreenIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className="contentHalf" >
                        <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                                Hoos Hack is UVA's flagship hackathon. For Hoos Hack 24, I worked on a team with one other person. My partner had much more
                                experience with hardware and I had much more experience with software, so we decided to combine our two skillsets and create A
                                physical, handheld gaming device. This project was one of the coolest I have worked on and was a great success. The idea was to make
                                a 2D, high score based platforming game and use various input methods to add variety. We ended up incorporating various senors like a force sensor
                                and an accelerometer as methods of control for the game. Our finished product was called "Quest for Meowtropolis" and featured a cat character 
                                dodging various obstacles. This project really taught me how to work under a crunch because we only had 24 hours to complete all of the coding,
                                wiring, and testing. 
                        </h1>
                    </div>
                </div>
                <div>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingTop: "20px", textAlign: "left", marginBlockEnd: "0", paddingLeft: "1%", paddingRight: "1%" }}>
                        Our project was built using an Arduino  microcontroller as well as various sensors and a small LED screen as a display. The software was built in 
                        Arduino IDE using C++. Finally, the whole thing was encased in a 3D printed shell. <br />
                    </h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", marginBlockEnd: "0" }}
                    >Check out the GitHub repo below!
                    </h1>
                </div>

                <ButtonGroup sx={{ width: "10%", margin: "auto", justifyContent: "center" }}>

                    <a href='https://github.com/NolanReedHill/QuestForMeowtropolis' target='_blank' rel='noreferrer'>

                        <IconButton>
                            <GitHubIcon sx={{ fontSize: "70px", color: "black" }} />
                        </IconButton>
                    </a>

                </ButtonGroup>
            </div>

            {isFullscreen &&
                <>
                    <div className="fullscreenImage" style={{ top: window.scrollY + (window.screen.height / 10) }}>

                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>

                            {pics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of games app website' style={{ maxHeight: "39vw", width: "60%"}} />
                            )}
                        </Carousel>

                        <div className='carouselButtons'>

                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={toggleFullscreen}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className='blocker' />
                </>}
        </>
    )
}