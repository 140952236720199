import './Social.css';
import { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import cmb1 from './images/cmb1.jpg';
import cmb2 from './images/cmb2.jpg';
import cmb3 from './images/cmb3.jpg';
import ACM from './images/ACM workshop.png';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

export default function Social() {

    const [autoplay, setAutoplay] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [whichPics, setWhichPics] = useState();

    useEffect(() => {
        if (isFullscreen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
    }, [isFullscreen]);

    let cmbPics = [
        cmb1, cmb2, cmb3
    ]

    function toggleFullscreen(picArray) {
        setWhichPics(picArray);
        setIsFullscreen(!isFullscreen);
    }

    window.addEventListener('keydown', function (e) {
        if (isFullscreen && e.key === "Escape") {
            setIsFullscreen(!isFullscreen);
        }
    });

    return (
        <>
            <div className='mainBodySocial'>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Cavalier Marching Band</h1>

                    <div className='socialContent'>

                        <div className="contentHalfImage" >
                            <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                                {cmbPics.map((pic, index) =>
                                    <img src={pic} key={index} className='pics' alt='Me during Marching Band.' />
                                )}
                            </Carousel>
                            <div className='carouselButtons'>
                                <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                    {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                                </IconButton>

                                <IconButton onClick={() => toggleFullscreen(cmbPics)}>
                                    <FullscreenIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className="contentHalf" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                                This year marks my sixth year of marching band. Marching band has taught me how to be an effective
                                teammate, and also given some of my closest friends. This season I lead the baritone section as section
                                leader. At around 250 members, UVA's Cavalier Marching Band has been a great challenge for my coordination
                                and ability, and I can't wait to continue the program next year.
                            </h1>

                        </div>

                    </div>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Concert Ensembles</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px" }}>
                        I play in two concert ensembles at UVA, the UVA Wind Ensemble and the UVA Concert Band. Both were a blast to be in, and both
                        catered to very different types of music. Below I talk a little about each.
                    </h1>

                    <div className='socialContent'>

                        <div className="contentHalfImage" style={{ filter: "none", backgroundColor: "inherit", border: "none" }}>
                            <iframe className='videoPlayer' src="https://www.youtube.com/embed/ibw7PjoOqRg?si=w09xAt-Q9x8tBrBd" title="YouTube video player"
                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>

                        <div className="contentHalf" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                                As the higher ensemble that requires an audition to get in, the Wind Ensemble really tested my ability to channel emotion and
                                depth through my playing. The pieces we played were  dark in theme, dealing with issues like suicide and mass tragedy, or they were
                                technically complex. To the right I've linked our Spring 2023 concert. Skip to 13:30 for the start of the concert.
                            </h1>

                        </div>

                    </div>

                    <div className='socialContent'>

                        <div className="contentHalf" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left" }}>
                                The concert band is the lower ensemble here at UVA, so it doesn't require an audition. The music we played was recognizable
                                and fun; we even played Star Wars! The big challenge with Concert Band was endurance, as we played a whopping 9 pieces in the Spring.
                                I've uploaded a recording of my favorite song from the concert, called "Branding Iron."
                            </h1>

                        </div>

                        <div className="contentHalfImage" style={{ filter: "none", backgroundColor: "inherit", border: "none" }}>
                            <iframe className="videoPlayer" src="https://drive.google.com/file/d/1LYbx67owGebM8PPg7I2Pv10dBJgGEzOg/preview" width="100%"
                                allow="autoplay" title='Drive Video Player' />
                        </div>

                    </div>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>UVA Association for Computer Machinery</h1>

                    <div className='socialContent'>

                        <div className="contentHalfImage" style={{ filter: "none", backgroundColor: "inherit", border: "none" }}>
                            <img src={ACM} alt='An ACM Resume workshop.' width="100%" style={{ borderRadius: "15px", height: "calc(12vw + 200px)" }} />
                        </div>

                        <div className="contentHalf" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                                ACM is the computer science networking club at UVA. Through events like resume workshops, professor dinners, and
                                interview prep sessions I've learned a lot about careers in computer science and how to best tackle interviews. These skills
                                have already helped me in my professional endeavors, like my acceptance into the Forge Launch program!
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left" }}>
                                Pictured here is an ACM resume workshop I attended.
                            </h1>

                        </div>

                    </div>
                </div>


            </div>

            {isFullscreen &&
                <>
                    <div className="fullscreenImage" style={{ top: window.scrollY + (window.screen.height / 10) }}>
                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                            {whichPics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of website' style={{ maxHeight: "39vw" }} />
                            )}
                        </Carousel>
                        <div className='carouselButtons'>
                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={() => toggleFullscreen("")}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className='forgeBlocker' />
                </>}
        </>
    )
}