import './FirstYearHighlights.css';
import filmReview from './media/Film-Review.pdf';
import criticalEssay from './media/Critical-Essay.pdf';
import personalNarrative from './media/Personal-Narrative.pdf';
import finalProjectPDF from './media/final-project.pdf';
import finalProjectAudio from './media/Final project mp3.mp3';
import whenYouWereYoungAudio from './media/When you were young audio.mp3';
import dictationProject from './media/dictationProject.pdf';

export default function FirstYearHighlights() {
    return (
        <>
            <div className='mainBodyFirstYearHighlights'>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>First Year Highlights</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                        On this page I've collated some of the highlights from my first two semesters at UVA. From analytic writing to music composition,
                        UVA has already given me so many new skills and experiences.
                    </h1>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>ENWR 1510</h1>


                    <div className='firstYearHighlightsContent'>

                        <div className='contentHalf'>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                Here I've embedded my first paper from my ENWR writing class. In this paper I was tasked to pick a scene from a movie that I connect with,
                                analyze the scene, and then narrate how that scene and its film of origin relate to my life. I wrote about Star Wars: Episode IV A New Hope,
                                a movie I hope you're familiar with. The challenge with this paper was making meaningful analyses of art, something I had never done before.
                                I learned how to translate my feelings and thoughts into well-articulated descriptions. Instead of just saying I like this, or this was bad,
                                I learned to articulate my beliefs as arguments and evidence. I believe the paper turned out very well, and demonstrates my analytic and writing
                                capabilities. I've linked my other papers from this class below.
                            </h1>

                            <div style={{
                                display: "flex", justifyContent: "space-between", width: "calc(20% + 150px)", marginLeft: "auto", marginRight: "auto",
                                paddingBottom: "10px", paddingTop: "30px"
                            }}>
                                <a href={filmReview} style={{ fontSize: "calc(.8vw + 10px)" }} download>
                                    Film Review
                                </a>

                                <a href={criticalEssay} style={{ fontSize: "calc(.8vw + 10px)" }} download>
                                    Critical Essay
                                </a>
                            </div>
                        </div>

                        <object
                            type="application/pdf"
                            data={personalNarrative + "#view=FitH"}
                            className="highlightPDF"
                            aria-label="Personal Narrative Essay."
                        />
                    </div>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Music</h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", textAlign: "left", }}>
                        In my first year at UVA, I enrolled in two music classes: MUSI 3310 (Theory I) and MUSI 3334 (Musicianship II).
                        Through both these classes I learned so much about music and how it works, and I created numerous projects that reflect my learning.
                        I've linked one project from each class that I feel best represents the main objectives of that class.
                    </h1>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Final Composition Project</h1>


                    <div className='firstYearHighlightsContent'>

                        <div className='contentHalf'>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                To the left is my final composition project from theory I. In theory I, I learned all about how music is composed and how different
                                styles of music in different cultures use the same chords and melodic ideas for different purposes. I chose to write a piece in 12 bar
                                blues, with a walking bassline and groovy beat. Learning about chords and harmonies for the first time was challenging. It required me to
                                think about music in a whole new way; I had to think about harmonic resonances, chordal progression, and how to write a melody. This project
                                put everything I learned in Theory I to the test, and I'm proud of the result. My project write-up that was part of the assignment is below.
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockTopStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                My piece is titled Raucous Roulette because I was told it sounds like a Mario minigame. I chose to write a 12-bar blues because they are fun
                                and straight to the point. My piece is 200 BPM and in the key of Eb major. I chose that tempo because I liked how the walking bass sounded when
                                it was faster. I chose the key of Eb because in my experience flat keys are very common for this style of music. The song is in 4/4 because I thought
                                the bass part works best in that meter. The piece consists of two repetitions of the 12-bar pattern.
                            </h1>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                The piano plays the 1 and 4 chords in a repeated pattern throughout the piece. It is joined by trumpets playing the chords every other measure.
                                The bassline walks up and down the dominant seventh chord in repeating two-measure phrases. During the first pass through the 12-bar pattern,
                                the bassline is at the forefront and plays a more rhythmically varied pattern. At the end of each 12-bar section, the whole band plays the final 2
                                measures together before winding down into the start of the next pattern. During the second time through, the bass falls to the background to allow
                                the new melody to shine through. In the second repetition, the trombone comes in with a solo using the Eb blues scale. The song ends in a short tag
                                from the trombone and one last hit from the band.

                            </h1>
                        </div>

                        <div className='pdfAudio'>

                            <object
                                type="application/pdf"
                                style={{ width: "100%" }}
                                data={finalProjectPDF + "#view=FitH"}
                                className="highlightPDF"
                                aria-label="Final Music Project"
                            />

                            <audio controls style={{ marginLeft: "auto", marginRight: "auto" }}>
                                <source src={finalProjectAudio} type='audio/ogg' />
                            </audio>
                        </div>
                    </div>
                </div>

                <div className='contentBox'>

                    <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Dictation Project</h1>


                    <div className='firstYearHighlightsContent'>

                        <div className='contentHalf'>

                            <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                                To the left is my dictation project from my musicianship II class. Musicianship was all about honing my listening and dictation skills.
                                I learned how to identify chords, intervals, and scales, and how to sing each of them. For this project I had to pick a song I liked, in my
                                case "When You Were Young" by The Killers, and dictate its melody, drumline, and bass. Dictation for me was a real challenge, and one that
                                I was not expecting going into this class. It took lots of practice in front of a piano before I was able to confidently analyze music with
                                just my ear alone. In this project, you can see how I used all of these new skills together to better connect with a song I've always loved.
                                I also embedded the original song, so you can see how close I got!
                            </h1>

                            <iframe className='videoPlayer' src="https://www.youtube.com/embed/ff0oWESdmH0?si=VQACVMglQwFZZlpa" title="YouTube video player"
                                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
                                style={{ width: "80%", height: "calc(10vw + 180px)" }} />
                        </div>

                        <div className='pdfAudio'>

                            <object
                                type="application/pdf"
                                style={{ width: "100%" }}
                                data={dictationProject + "#view=FitH"}
                                className="highlightPDF"
                                aria-label="Final Music Project"
                            />

                            <audio controls style={{ marginLeft: "auto", marginRight: "auto", paddingBottom: "10px" }}>
                                <source src={whenYouWereYoungAudio} type='audio/ogg' />
                            </audio>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}