import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Navbar from "./components/navbar/Navbar";
import './App.css';
import Homepage from "./pages/homepage/Homepage";
import SecondYearHighlights from "./pages/second year highlights/SecondYearHighlights";
import FirstYearHighlights from "./pages/first year highlights/FirstYearHighlights";
import ForgeLaunch from "./pages/forge launch/ForgeLaunch";
import GamesApp from "./pages/games app/GamesApp";
import ReflectionOnLearning from "./pages/reflection on learning/ReflectionOnLearning";
import Resume from "./pages/resume/Resume";
import Social from "./pages/social/Social";
import Values from "./pages/values/Values";
import BiteBack from "./pages/biteback/BiteBack";
import HoosHack24 from "./pages/hoos hack 24/HoosHack24";

function App() {
  const theme =
    createTheme({
      palette: {
        primary: {
          main: "#c5e1a5"
        },
        secondary: {
          main: "#f2f7f4"
        },
      }
    });


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/Forge-Launch" element={<ForgeLaunch />} />
            <Route path="/Games-App" element={<GamesApp />} />
            <Route path="/Second-Year-Highlights" element={<SecondYearHighlights />} />
            <Route path="/First-Year-Highlights" element={<FirstYearHighlights />} />
            <Route path="/Values" element={<Values />} />
            <Route path="/Social" element={<Social />} />
            <Route path="/Reflection-on-Learning" element={<ReflectionOnLearning />} />
            <Route path="/BiteBack" element={<BiteBack />} />
            <Route path="/Hoos-Hack-24" element={<HoosHack24 />} />

          </Routes>
        </BrowserRouter>
      </ThemeProvider>

    </div>
  );
}

export default App;
