import './Values.css';

export default function Values() {
    return (
        <>

            <div className="mainBodyValues" >

                <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>Statement of Values</h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    As an Eagle Scout, I live my life with a strong set of values and beliefs that I hold in high esteem.
                    I’ve chosen what I believe to be the three most important tenets of the Scout Law to exemplify my way of life.
                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    Trustworthy. A team is built on trust. I’ve been on many teams in my life, from working in a kitchen to
                    leading in a marching band. Trust is the glue that binds a team together, and without that, no team can be successful.
                    In my life, I aim to be someone that can be confided in with secrets, sought after for help, and to have a reputation
                    for listening. Part of being a trustworthy person is seeking that same value in others. I hold the people around me
                    to the same high standard to which I hold myself.
                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    Cheerful. As someone who holds leadership positions across various organizations, being cheerful is
                    one of the most important aspects of my life. I seek to have a positive impact on the days of every person
                    I interact with. A positive environment allows everyone to perform better, whatever the task. By doing my best
                    to bring positivity to the places I go to I invite others to do the same. I often benefit from the reciprocated
                    kindness of others that exists in the groups and spaces I participate in.
                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    Clean. Clean is an ambiguous term, ranging from hygiene to moral character. I like to apply
                    this ideal to many different areas of my life. Starting with the more literal, I use the idea of cleanliness
                    to represent the maintenance of health, both physical and mental. It is important to me that I take care of
                    my body and my mind, and allow myself to perform at my best at all times. The second way I use cleanliness in my
                    life is through organization. To me, cleanliness represents a well organized room, workspace, and notebook. These
                    things are the foundation of my learning, productivity, and health. By allowing myself a sturdy bedrock, I never
                    fear that the foundation I walk upon will collapse or push me off balance.

                </h1>

                <h1 style={{ fontSize: "calc(.8vw + 10px)", textAlign: "left", paddingLeft: "10px", paddingRight: "10px" }}>
                    These were three of the standards that I hold myself to everyday. There are many more things that I hold
                    important to me, but I believe that these three form the axis that all other important values fall in between.
                    As I continue my college career, I hope to uphold these values throughout every aspect of my life, and I expect the
                    same from the people around me.
                </h1>

            </div>

        </>
    )
}