import Carousel from 'react-material-ui-carousel';
import './GamesApp.css';
import { useState, useEffect } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GitHubIcon from '@mui/icons-material/GitHub';
import pic1 from './images/gamesapp1.png';
import pic2 from './images/gamesapp2.png';
import pic3 from './images/gamesapp3.png';
import pic4 from './images/gamesapp4.png';
import pic5 from './images/gamesapp5.png';
import pic6 from './images/gamesapp6.png';


export default function GamesApp() {

    const [autoplay, setAutoplay] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    window.addEventListener('keydown', function (e) {
        if (isFullscreen && e.key === "Escape") {
            setIsFullscreen(!isFullscreen);
        }
    });

    let pics = [
        pic1, pic2, pic3, pic4, pic5, pic6
    ]

    function toggleFullscreen() {
        setIsFullscreen(!isFullscreen);
    }

    useEffect(() => {
        if (isFullscreen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
    }, [isFullscreen]);

    return (
        <>
            <div className='mainBodyGamesApp'>

                <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>My Games App</h1>

                <div className='gamesAppContent'>

                    <div className="contentHalfImage">

                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                            {pics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of games app website' />
                            )}
                        </Carousel>

                        <div className='carouselButtons'>

                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={toggleFullscreen}>
                                <FullscreenIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className="contentHalf" >
                        <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                            After completing Forge Launch, I decided to start working on a personal React project. I decided to create an online games website because I've
                            always been a huge fan of video games. This app is an opportunity for me to practice all the skills I learned in React, and to discover new ones.
                            For instance, when I decided to code an online multiplayer game, tic-tac-toe, I needed to figure out how to connect two players across the web. I
                            ended up using Stream Chat, a messaging API that is mainly built for chat messaging. Because Stream Chat handles websocket connections and user
                            accounts, I was able to use it to implement my real-time multiplayer game. Currently on my games app you can play minesweeper and tic-tac-toe,
                            but I plan on adding more games in the future.
                        </h1>
                    </div>
                </div>
                <div>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingTop: "20px", textAlign: "left", marginBlockEnd: "0", paddingLeft: "1%", paddingRight: "1%" }}>
                        My games app was built using React for front end, and Express.js for backend. I used Google Firestore as a database, and I used Stream Chat
                        for multiplayer connectivity and user accounts. The front end is deployed using Netlify, and the back end is deployed using Render. Website,
                        front end, back end. <br />
                    </h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", marginBlockEnd: "0" }}
                    >Check out the deployed app or the GitHub repo below!
                    </h1>
                </div>

                <ButtonGroup sx={{ width: "10%", margin: "auto", justifyContent: "center" }}>

                    <a href='https://nolans-games.netlify.app/' target='_blank' rel='noreferrer'>
                        <IconButton sx={{ color: "black" }}>
                            <p className='joystick'>🕹️</p>
                        </IconButton>
                    </a>

                    <FiberManualRecordIcon sx={{ margin: "auto", width: "50%", fontSize: "10px" }} />

                    <a href='https://github.com/NolanReedHill/GamesAppFrontend' target='_blank' rel='noreferrer'>

                        <IconButton>
                            <GitHubIcon sx={{ fontSize: "70px", color: "black" }} />
                        </IconButton>
                    </a>

                </ButtonGroup>
            </div>

            {isFullscreen &&
                <>
                    <div className="fullscreenImage" style={{ top: window.scrollY + (window.screen.height / 10) }}>

                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>

                            {pics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of games app website' style={{ maxHeight: "39vw" }} />
                            )}
                        </Carousel>

                        <div className='carouselButtons'>

                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={toggleFullscreen}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className='blocker' />
                </>}
        </>
    )
}