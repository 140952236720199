import Carousel from 'react-material-ui-carousel';
import './BiteBack.css';
import { useState, useEffect } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GitHubIcon from '@mui/icons-material/GitHub';
import pic1 from './media/homepage.png';
import pic2 from './media/login.png';
import pic3 from './media/newpost.png';

export default function BiteBack() {
    const [autoplay, setAutoplay] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);

    window.addEventListener('keydown', function (e) {
        if (isFullscreen && e.key === "Escape") {
            setIsFullscreen(!isFullscreen);
        }
    });

    let pics = [
        pic1, pic2, pic3, 
    ]

    function toggleFullscreen() {
        setIsFullscreen(!isFullscreen);
    }

    useEffect(() => {
        if (isFullscreen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'auto';
    }, [isFullscreen]);

    return (
        <>
            <div className='mainBodyGamesApp'>

                <h1 style={{ padding: "0px", fontSize: "calc(1vw + 25px)" }}>BiteBack</h1>

                <div className='gamesAppContent'>

                    <div className="contentHalfImage">

                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>
                            {pics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of games app website' />
                            )}
                        </Carousel>

                        <div className='carouselButtons'>

                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={toggleFullscreen}>
                                <FullscreenIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className="contentHalf" >
                        <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlock: "0", textAlign: "left" }}>
                            In spring 2024 I took CS 3240, Advanced Software Development. The goal of this class was to gain experience working on large-scale 
                            projects on a team, and learning industry practices and techniques. BiteBack was the semester-long project that was worked on by me and four 
                            other students. The idea behind BiteBack is that it is a forum site where users can share information about local restaurants such as 
                            which menu items are unavailable or where wait times are long. BiteBack features four different user types as well as features like reporting posts
                            and admin review for posts. Working on this project taught me a lot about working on a team and how to divide and conquer so the whole ButtonGroup
                            could be successful. Additionally, we produced various design documents, wireframes, and progress reports that helped us simulate 
                            what it would be like creating this project for a real company. 
                        </h1>
                    </div>
                </div>
                <div>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", paddingTop: "20px", textAlign: "left", marginBlockEnd: "0", paddingLeft: "1%", paddingRight: "1%" }}>
                        BiteBack was built in Django and Python for the front end. The app was deployed using Heroku, which is where the PostgreSQL database was stored. 
                        Additionally, BiteBack used Amazon S3 storage to handle photos and other files attached to posts. Google OAuth was used for authentication, and Django Bootstrap 5
                        was used for styling the app.  <br />
                    </h1>

                    <h1 style={{ fontSize: "calc(.8vw + 10px)", marginBlockStart: "0", marginBlockEnd: "0" }}
                    >Check out the GitHub repo below!
                    </h1>
                </div>

                <ButtonGroup sx={{ width: "10%", margin: "auto", justifyContent: "center" }}>

                    <a href='https://github.com/NolanReedHill/BiteBack' target='_blank' rel='noreferrer'>

                        <IconButton>
                            <GitHubIcon sx={{ fontSize: "70px", color: "black" }} />
                        </IconButton>
                    </a>

                </ButtonGroup>
            </div>

            {isFullscreen &&
                <>
                    <div className="fullscreenImage" style={{ top: window.scrollY + (window.screen.height / 10) }}>

                        <Carousel sx={{}} navButtonsAlwaysVisible="true" autoPlay={autoplay} interval={2500}>

                            {pics.map((pic, index) =>
                                <img src={pic} key={index} className='pics' alt='Screenshot of BiteBack' style={{ maxHeight: "39vw" }} />
                            )}
                        </Carousel>

                        <div className='carouselButtons'>

                            <IconButton onClick={() => setAutoplay(!autoplay)} sx={{ padding: "5px" }}>
                                {autoplay ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <IconButton onClick={toggleFullscreen}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </div>
                    </div>

                    <div className='blocker' />
                </>}
        </>
    )
}