import "./Resume.css";
import resume from "./pdfs/Nolan_Hill_Resume_PDF.pdf";

export default function Resume() {
    return (
        <>
            <div className="contentBox" style={{ marginLeft: "30px", marginRight: "30px", marginTop: "100px", marginBottom: "50px" }}>

                <object
                    type="application/pdf"
                    data={resume + "#view=FitH"}
                    className="resume"
                    aria-label="Nolan Hill Resume."
                />
            </div>
        </>
    )
}